<!--<template>-->

<!--  <div id="workInd" v-if="testData.length == undefined">-->
<!--    <h2>{{testData.title}}</h2>-->
<!--    <h3>{{testData.date}}</h3>-->
<!--    &lt;!&ndash;<img alt="testImage" :src="require('../assets/' + testData.image)"> ASYNC ZZZZ &ndash;&gt;-->
<!--    <p>{{testData.desc}}</p>-->

<!--  </div>-->

<!--  <div v-else>-->
<!--    <h6>{{ testData }}</h6>-->
<!--    <router-link to="/">Go back</router-link>-->


<!--  </div>-->



<!--</template>-->


<template>

  <div class="bodyWrapper">


  <div id="workInd" v-if="testData.length === undefined">
<!--    <h2>{{testData.title}}</h2>-->
<!--    <h3>{{testData.date}}</h3>-->



<!--    <p id="description">{{testData.desc}}</p>-->

    <p id="description">This part of the website is still under development!</p>



<!--    <img id="titleImage" alt="work image" :src="require('../assets/' + testData.image)">-->

<!--    <div class="extensive">-->
<!--      <h5 class="subtitle">{{testData.title1}}</h5>-->
<!--      <h5 class="subtitle">{{testData.title2}}</h5>-->
<!--    </div>-->

  </div>

  <div v-else>
    <h6>{{ testData }}</h6>
    <router-link to="/">Go back</router-link>


  </div>


  </div>

</template>


<script>

import data from '../../data/test.json'



export default {
  data() {
    return {
      testData: "",
    };
  },
  mounted() {
    this.testData = data.find( d => d.title === this.$route.params.id) || "This work is not available"
  }
}


</script>

<style scoped>

  .bodyWrapper {
    background-color: #2C4252;
  }

#workInd{
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  color: white;
}

#workInd h2 {
  flex: 100%;
  align-self: center;
  font-family: Comfortaa, sans-serif;
  font-size: 3em;
}

#workInd h3{
  align-self: center;
  font-family: Comfortaa, sans-serif;
}

#workInd p {
  margin-top: 50px;
  margin-bottom: 50px;
}

#titleImage {
  width: 50%;
  align-self: center;
  background: red;
}

#description {
  text-align: center;
  align-self: center;
  font-family: Comfortaa, sans-serif;
  margin-bottom: 1em;
}

.subtitle {
  font-family: Comfortaa, sans-serif;
  text-decoration: underline;
  align-self: flex-start;
  font-size: 2.7em;
}


.extensive {
  background: red;
  width: 50%;
  align-self: center;
}


#TODO MOBILE





h6 {
  color: #ffe600;
  font-size: 30px;
  margin: 50px;
}
</style>