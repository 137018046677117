<template>
  <div class="bodyWrapper">
    <div class="resume">
      <p id="description">This part of the website is still under development!</p>

    </div>
  </div>


</template>

<style scoped>

  .resume {
    justify-content: center;
    display: flex;
  }

  .bodyWrapper {
    text-align: center;
    background-color: #2C4252;
  }


  #description {
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    align-self: center;
    font-family: Comfortaa, sans-serif;
  }

</style>

