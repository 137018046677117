<template>
  <div class="bodyWrapper">

    <main>
      <div id="contact">
        <h2 class="title">let's talk</h2>
        <ul id="contactul">

                    <li><img :src="require('../assets/email3.png')" alt="Email"><a href="mailto:thomasbogar@hotmail.com">Send me an email</a></li>
                    <li><img :src="require('../assets/linkedin3.png')" alt="LinkedIn"><a href="https://www.linkedin.com/in/thomas-bogar-4b6686200/">Visit me on LinkedIn</a></li>
            <li><img :src="require('../assets/discord3.png')" alt="Discord"><a href="mailto:thomasbogar@hotmail.com?subject=Information&body=Hey Thomas, I would like to message you on Discord, could you please give me your #? Thanks!">Message me on Discord</a></li>

        </ul>
      </div>
    </main>
  </div>
</template>
<style scoped>


  #contact {
    padding-bottom: 50px;
  }

  a {

    text-decoration: none;
    color: white;
    font-family: Comfortaa, sans-serif;
  }

  a:hover {
    color: #FFC100;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }



  .bodyWrapper {
    background-color: #2C4252;
  }

  @media (max-width : 1450px) {

    img {
        padding-right: 50px;

        width: 75px;
      height: auto;
    }
    main {
      max-width: 65%;
      margin: 0 auto;
      height: 400%;
    }

    .title {
      padding-top: 50px;
      color: white;
      font-family: 'Comfortaa', cursive;
      margin-bottom: 20px;
      font-size: 2em;
    }
  }

  @media (min-width: 1450px) {
    img {
        padding-right: 50px;
        width: 100px;
      height: auto;
    }
    main {
      max-width: 50%;
      margin: 0 auto;
      height: 400%;
    }

    .title {
      padding-top: 50px;
      color: white;
      font-family: 'Comfortaa', cursive;
      margin-bottom: 20px;
      font-size: 3em;
    }
  }
</style>