


<template>

    <div id="blogInd" v-if="blog.length === undefined">
        <div id="titleBox">
            <h2>{{blog.title}}</h2>
            <h3>{{blog.date}}</h3>
<!--            <img id="titleImage" alt="work image" :src="require('../assets/' + blog.thumbnail)">-->
        </div>

        <div id="content">

            <p id="description">{{blog.desc}}</p>
            <img id="image1" alt="work image" :src="require('../assets/' + blog.image1)">


            <p id="description2">{{blog.desc2}}</p>
            <img id="image2" alt="work image" :src="require('../assets/' + blog.image2)">


            <p id="description3">{{blog.desc3}}</p>
            <p id="description4">{{blog.desc4}}</p>

            <img id="image3" alt="work image" :src="require('../assets/' + blog.image3)">



        </div>




    </div>

    <div v-else>
        <div class="notAvailable">
        <h6>{{ blog }}</h6>
        <router-link to="/">Go back</router-link>
        </div>
    </div>

</template>


<script>

    import data from '../../data/blogs.json'

    export default {
        data() {
            return {
                blog: "",
            };
        },
        mounted() {
            this.blog = data.find( d => d.title === this.$route.params.id) || "This blog is not available"
        }
    }

</script>

<style scoped>


    @media (max-width : 1450px) {
        #blogInd {
            font-family: Roboto, sans-serif ;
            margin: 0 auto;
            max-width: 65%;
        }
    }

    @media (min-width: 1450px) {
        #blogInd {
            font-family: Roboto, sans-serif ;
            margin: 0 auto;
            max-width: 40%;
        }
    }


    #description4 {
        margin-top: 10px;
    }

    #titleBox {
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
    }

    #titleBox h2 {
        font-family: Comfortaa, sans-serif;
        font-size: 50px;
    }

    #titleBox h3 {
        font-family: Comfortaa, sans-serif;
    }

    #content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }

    img {
        margin: 40px 0 40px 0;
        max-width: 100%;
    }

    img {

        box-shadow: 2px 0px 0px 0px #FFC100;
    }



    .notAvailable {
        margin: 50px 0 50px 0;
        text-align: center;
        font-family: Comfortaa, sans-serif;
    }

    .notAvailable h6 {
        font-size: 50px;
    }







</style>